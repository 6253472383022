/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Image,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../context/GlobalContext";
import MainLayout from "../mainLayout/MainLayout";
import { v4 as uuidv4 } from "uuid"; // Genero uuid diferentes para evitar repetición en prop key
import "./index.css";
import GETListarMediosPago from "../../helpers/integraciones/GETListarMediosPago";
import DrawerMediosPago from "./drawerMediosPago/DrawerMediosPago";
import GETObtenerIntegracion from "../../helpers/integraciones/GETObtenerIntegracion";
import dayjs from "dayjs";
import { exportMPToXLS } from "../../utils/exportMPToXLS";
import { importAssets } from "../../utils/importAssets";

// Recibe como props integraciones al igual que Dashboard
const MediosPago = ({ integrations }) => {
  const images = importAssets(
    require.context("../../assets/integraciones", false, /\.(png|svg)$/)
  );

  const tarjetaImages = importAssets(
    require.context("../../assets/tarjetas", false, /\.(png|svg)$/)
  );

  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedMedioPago, setSelectedMedioPago] = useState(null);
  const [lastSyncDate, setLastSyncDate] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);

  const { setSelectedIntegration, selectedIntegration, loggedUser } =
    useContext(GlobalContext);

  useEffect(() => {
    if (integrations && integrations.length > 0) {
      integrations.sort((a, b) => a.nombre.localeCompare(b.nombre));

      const fetchData = async () => {
        setLoadingTable(true);

        try {
          // Realiza ambas solicitudes en paralelo
          const [resultMediosPago, syncResult] = await Promise.all([
            GETListarMediosPago(loggedUser, selectedIntegration),
            selectedIntegration?.codigo === "MB"
              ? GETObtenerIntegracion(loggedUser, selectedIntegration)
              : Promise.resolve(null), // Si no es MB, devuelve una promesa resuelta con null
          ]);

          // Actualiza el estado con los resultados de ambas solicitudes
          if (resultMediosPago) {
            setData(resultMediosPago);
          }
          if (syncResult) {
            setLastSyncDate(
              dayjs(syncResult.mobbexUltimaSincro).format("DD/MM/YYYY HH:mm")
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoadingTable(false);
        }
      };

      fetchData();
    }
  }, [selectedIntegration, integrations, loggedUser]);

  const handleDrawerClose = (updatedRecord) => {
    setOpenDrawer(false);
    if (updatedRecord) {
      setData((prevData) =>
        prevData.map((item) =>
          item.nombreMediosPago === updatedRecord.nombreMediosPago
            ? updatedRecord
            : item
        )
      );
    }
  };

  const renderFilter = () => {
    // Fx encargada de crear el header donde elegir integraciones
    if (integrations && integrations.length > 0) {
      return integrations.map((item, index) => {
        if (item.activo) {
          let imageSrc = images[item.codigo];

          if (imageSrc === undefined) {
            imageSrc = images["DEFAULT"];
          }

          return (
            <Tooltip title={item.nombre} key={uuidv4()}>
              <Card
                style={{
                  width: "55px",
                  height: "55px",
                  borderColor: "#808080a1",
                  margin: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="integration-wrapper"
                onClick={() => toggleSelection(item)}
                bordered={
                  item.idIntegracion === selectedIntegration?.idIntegracion &&
                  true
                }
                hoverable
              >
                <Image src={imageSrc} preview={false} width={30} />
              </Card>
            </Tooltip>
          );
        }
        return null;
      });
    }
  };

  const getColumnSearchProps = (dataIndex, placeholder) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
      clearFilters();
      handleSearch([""], confirm, dataIndex);
    };

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar por ${placeholder}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() =>
                clearFilters && handleReset(clearFilters, confirm, dataIndex)
              }
              size="small"
              style={{ width: 90 }}
            >
              Reiniciar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "codigo") {
          // Verificar si algún objeto dentro del array 'codigo' coincide con el valor de búsqueda
          const matchFound = record.codigo.some((item) => {
            return (
              item.codigoERP &&
              item.codigoERP.toLowerCase().includes(value.toLowerCase())
            );
          });

          return matchFound;
        } else {
          // Manejar la búsqueda para otros campos simples
          return record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : "";
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) => <span>{text}</span>,
    };
  };

  const handleModifyMedioPago = (record) => {
    setSelectedMedioPago(record);
    setOpenDrawer(true);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombreMediosPago",
      ellipsis: true,
      width: "10%",
      editable: false,
      ...getColumnSearchProps("nombreMediosPago", "nombre"),
      render: (dataIndex) => {
        const tarjetaNombre = dataIndex.replace(/ /g, "").toLowerCase();
        const imageSrc =
          tarjetaImages[tarjetaNombre] || tarjetaImages["default-card"];
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text strong>{dataIndex}</Typography.Text>
            {imageSrc && <Image src={imageSrc} width={35} preview={false} />}
          </div>
        );
      },
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      width: "5%",
      editable: false,
      filters: [
        {
          text: "DÉBITO",
          value: 1,
        },
        {
          text: "CRÉDITO",
          value: 2,
        },
      ],
      onFilter: (value, record) => {
        return record.tipo === value;
      },
      render: (dataIndex) => {
        return <span>{dataIndex === 1 ? "DÉBITO" : "CRÉDITO"}</span>;
      },
    },
    {
      title: "Referencia ERP",
      dataIndex: "codigo",
      width: "20%",
      editable: true,
      ...getColumnSearchProps("codigo", "código"),
      render: (dataIndex, row) => {
        return (
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {dataIndex.map((item, idx) => {
              if (item.idMediosPagoERPIntegracion !== 0 && item.codigoERP) {
                return (
                  <Tag
                    className="custom-tag-mp"
                    bordered={false}
                    key={uuidv4()}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    {item.codigoERP}
                    {item.descripcionERP && ` - ${item.descripcionERP}`}
                  </Tag>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "",
      width: "2%",
      key: "action",
      render: (_, record) => {
        return (
          <EditOutlined
            className="edit-icon"
            onClick={() => {
              handleModifyMedioPago(record);
            }}
          />
        );
      },
    },
  ];

  const toggleSelection = (integration) => {
    // Cambio de integración activa
    setSelectedIntegration(integration);
  };

  const locale = {
    emptyText: "No hay medios de pago para esta integración.",
  };

  const handleDownloadXLS = () => {
    try {
      exportMPToXLS(
        data,
        `medios_pago_${selectedIntegration.codigo}`,
        "MediosPago"
      );
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <MainLayout>
      <div className="filter-wrapper">
        <div className="filter-wrapper">{renderFilter()} </div>
        <div className="filter-right-wrapper">
          {selectedIntegration?.codigo === "MB" && lastSyncDate && (
            <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
              Últ. sinc.: {lastSyncDate}
            </Typography.Text>
          )}
          {selectedIntegration && (
            <Popconfirm
              placement="right"
              title="¿Desea exportar la información a XLS?"
              onConfirm={handleDownloadXLS}
              okText="Sí"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 8, marginRight: 8 }}
                type="dashed"
                icon={
                  <FileExcelOutlined style={{ color: "green", fontSize: 14 }} />
                }
              />
            </Popconfirm>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <Table
          sticky={{ offsetHeader: 100 }}
          scroll={{ x: "max-content" }}
          loading={loadingTable}
          locale={locale}
          size="small"
          rowKey={(record) => `${record.idMediosPago + uuidv4()}`}
          bordered
          dataSource={Array.isArray(data) ? data : []}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 50,
          }}
        />
      </div>
      <DrawerMediosPago
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        selectedMedioPago={selectedMedioPago}
        setSelectedMedioPago={setSelectedMedioPago}
        onClose={handleDrawerClose}
      />
    </MainLayout>
  );
};

export default MediosPago;
