import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Empty,
  Spin,
  Space,
} from "antd";
import { GlobalContext } from "../../../../context/GlobalContext";
import GETSucursalesDisponibles from "../../../../../helpers/empresas/sucursales/GETSucursalesDisponibles";
import GETSucursalesPorEmpresa from "../../../../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import { POSTAsociarSucursal } from "../../../../../helpers/empresas/sucursales/POSTAsociarSucursal";
import { POSTDesAsociarSucursal } from "../../../../../helpers/empresas/sucursales/POSTDesAsociarSucursal";
import {
  LinkOutlined,
  PlusOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";
import { useNotification } from "../../../../notification/OpenNotification";

const SucursalesForm = ({ integrationData }) => {
  const openNotification = useNotification();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [sucursalesDisponibles, setSucursalesDisponibles] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const { loggedUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [selectedBranches, setSelectedBranches] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const dataSucursalesDisponibles = await GETSucursalesDisponibles(
          loggedUser,
          integrationData?.codigo
        );
        setSucursalesDisponibles(dataSucursalesDisponibles);

        const dataSucursales = await GETSucursalesPorEmpresa(
          loggedUser,
          loggedUser?.codEmpresa
        );

        setSucursales(dataSucursales);
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [integrationData, loggedUser]);

  const handleAssociate = async (sucursal, selectedBranch, nombreMP) => {
    const payload = {
      codSucursal: sucursal.codigo,
      codEmpresa: loggedUser.codEmpresa,
      codIntegracion: integrationData.codigo,
      codInterno: selectedBranch,
      nombreMP: nombreMP,
    };

    const response = await POSTAsociarSucursal(payload, loggedUser);

    if (response.status === 200) {
      const updatedSucursales = sucursales.map((s) => {
        if (s.codigo === sucursal.codigo) {
          return {
            ...s,
            codInternoMP: selectedBranch,
            nombreMP: nombreMP,
          };
        }
        return s;
      });

      setSucursales(updatedSucursales);

      openNotification({
        type: "success",
        message: "Cambios aplicados correctamente.",
        duration: 1.5,
      });
    } else {
      openNotification({
        type: "error",
        message: "Hubo un error al aplicar los cambios.",
        duration: 1.5,
      });
    }
  };

  const handleSelectChange = (sucursalId, value, all) => {
    setSelectedBranches((prev) => ({
      ...prev,
      [sucursalId]: value,
      nombreMP: all.children,
    }));
  };

  const handleUndoAssociate = async (sucursal) => {
    const payload = {
      codSucursal: sucursal.codigo,
      codEmpresa: loggedUser.codEmpresa,
      codIntegracion: integrationData.codigo,
    };

    try {
      const response = await POSTDesAsociarSucursal(loggedUser, payload);

      if (response.status === 200) {
        // Actualizar el estado eliminando la asociación de la sucursal
        const updatedSucursales = sucursales.map((s) =>
          s.codigo === sucursal.codigo
            ? { ...s, codInternoMP: null, nombreMP: null }
            : s
        );

        setSucursales(updatedSucursales);

        openNotification({
          type: "success",
          message: "Sucursal desvinculada correctamente.",
          duration: 1.5,
        });
      } else {
        openNotification({
          type: "error",
          message: "Error al desvincular la sucursal.",
          duration: 1.5,
        });
      }
    } catch (error) {
      console.error("Error al desvincular sucursal:", error);
      openNotification({
        type: "error",
        message: "Error al desvincular la sucursal.",
        duration: 1.5,
      });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "70dvh",
          justifyContent: "center",
        }}
      >
        <Spin size="default" />
      </div>
    );
  }

  return (
    <Form form={form} layout="vertical" autoComplete="off" name="configForm">
      {sucursales && sucursales.length > 0 ? (
        sucursales.map((sucursal) => {
          const isLinkEnabled = selectedBranches[sucursal.codigo];
          return (
            <Row key={sucursal.codigo} gutter={16} style={{ marginBottom: 16 }}>
              <Col span={22}>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    value={sucursal.nombre}
                    placeholder="Sucursal"
                    readOnly
                    style={{ flex: 1 }}
                  />
                  {sucursal.codInternoMP ? (
                    <Select
                      value={sucursal.codInternoMP}
                      style={{ flex: 2 }}
                      readOnly
                    >
                      <Option value={sucursal.codInternoMP}>
                        {sucursal.nombreMP || "-"}
                      </Option>
                    </Select>
                  ) : (
                    <Select
                      allowClear
                      placeholder="Selecciona una sucursal disponible"
                      style={{ flex: 2 }}
                      onChange={
                        (value, all) =>
                          handleSelectChange(sucursal.codigo, value, all) //Nombre de la sucursal que voy a enlazar de MP
                      }
                    >
                      {sucursalesDisponibles &&
                        sucursalesDisponibles.length > 0 &&
                        sucursalesDisponibles?.map((availableBranch) => (
                          <Option
                            key={availableBranch.codInternoMP}
                            value={availableBranch.codInternoMP}
                          >
                            {availableBranch.nombre}
                          </Option>
                        ))}
                      <Option value="crearNueva">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PlusOutlined style={{ marginRight: 8 }} />
                          Nueva sucursal
                        </span>
                      </Option>
                    </Select>
                  )}
                </Space.Compact>
              </Col>
              <Col
                span={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!sucursal.codInternoMP ? (
                  <Tooltip title="Asociar sucursal">
                    <LinkOutlined
                      className={isLinkEnabled && "edit-icon"}
                      style={{
                        color: isLinkEnabled ? "#1890ff" : "#d9d9d9",
                        cursor: isLinkEnabled ? "pointer" : "not-allowed",
                      }}
                      onClick={() =>
                        isLinkEnabled &&
                        handleAssociate(
                          sucursal,
                          selectedBranches[sucursal.codigo],
                          selectedBranches.nombreMP
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Desvincular sucursal">
                    <DisconnectOutlined
                      className={"edit-icon"}
                      style={{
                        color: "#1890ff",
                        cursor: "pointer",
                      }}
                      onClick={() => handleUndoAssociate(sucursal)}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
          );
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No hay sucursales para esta empresa."
        />
      )}
    </Form>
  );
};

export default SucursalesForm;
