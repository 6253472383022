import { Tabs, Typography } from "antd";
import { DatabaseOutlined, KeyOutlined, ToolOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import DataForm from "./DataForm";
import "../../index.css";
import CredentialsForm from "./CredentialsForm";
import { GlobalContext } from "../../../context/GlobalContext";
import GETObtenerIntegracion from "../../../../helpers/integraciones/GETObtenerIntegracion";
import ConfigurationTabs from "./configurationTabs/ConfigurationTabs";

const TabsIntegraciones = ({ drawerIntegration }) => {
  const [integrationData, setIntegrationData] = useState({});
  const [lastSyncDate, setLastSyncDate] = useState("");

  const { loggedUser } = useContext(GlobalContext);

  useEffect(() => {
    const fetchData = async () => {
      if (drawerIntegration) {
        const { integration } = drawerIntegration;
        const data = await GETObtenerIntegracion(loggedUser, integration);
        if (data) {
          setIntegrationData(data);
          setLastSyncDate(data.mobbexUltimaSincro);
        }
      }
    };

    fetchData();
  }, [loggedUser, drawerIntegration]);

  const renderSucCaja = integrationData.configuraSucCaja;
  const renderDispositivo = integrationData.configuraDispositivo;

  const tabItems = [
    {
      key: "1",
      label: (
        <span>
          <DatabaseOutlined style={{ marginRight: 8 }} />
          <Typography.Text strong>Datos</Typography.Text>
        </span>
      ),
      children: (
        <DataForm
          drawerIntegration={drawerIntegration}
          integrationData={integrationData}
          lastSyncDate={lastSyncDate}
          setLastSyncDate={setLastSyncDate}
        />
      ),
    },
    {
      key: "2",
      label: (
        <span>
          <KeyOutlined style={{ marginRight: 8 }} />
          <Typography.Text strong>Credenciales</Typography.Text>
        </span>
      ),
      children: <CredentialsForm integrationData={integrationData} />,
    },
  ];

  if (renderSucCaja || renderDispositivo) {
    tabItems.push({
      key: "3",
      label: (
        <span>
          <ToolOutlined style={{ marginRight: 8 }} />
          <Typography.Text strong>Configuración</Typography.Text>
        </span>
      ),
      children: (
        <ConfigurationTabs
          integrationData={integrationData}
          renderSucCaja={renderSucCaja}
          renderDispositivo={renderDispositivo}
        />
      ),
    });
  }

  return <Tabs defaultActiveKey="1" items={tabItems} size="middle" />;
};

export default TabsIntegraciones;
