import { Drawer, Image } from "antd";
import React from "react";
import TabsIntegraciones from "./tabsIntegraciones/TabsIntegraciones";
import { importAssets } from "../../../utils/importAssets";

const DrawerIntegracion = ({ drawerIntegration, setDrawerIntegration }) => {
  const images = importAssets(
    require.context("../../../assets/integraciones", false, /\.(png|svg)$/)
  );

  const onClose = () => {
    setDrawerIntegration(null);
  };

  const drawerStyles = {
    body: {
      padding: "0 16px 16px",
    },
  };

  const renderDrawerTitle = () => {
    if (drawerIntegration && drawerIntegration?.integration) {
      const { codigo, nombre } = drawerIntegration?.integration;
      const imageSrc = images[codigo];
      return (
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{nombre}</span>
          <span>
            <Image src={imageSrc} preview={false} width={35} />
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <Drawer
        width={520}
        destroyOnClose
        maskClosable={false}
        title={renderDrawerTitle()}
        // title={drawerIntegration?.integration?.nombre}
        placement={"right"}
        onClose={onClose}
        open={drawerIntegration?.visible}
        key={"drawerIntegracion"}
        styles={drawerStyles}
      >
        <TabsIntegraciones drawerIntegration={drawerIntegration} />
      </Drawer>
    </>
  );
};

export default DrawerIntegracion;
