import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CopyOutlined, SyncOutlined } from "@ant-design/icons";
import GETSincronizacion from "../../../../helpers/integraciones/GETSincronizacion";

dayjs.extend(utc);
dayjs.extend(timezone);

const DataForm = ({
  drawerIntegration,
  integrationData,
  lastSyncDate,
  setLastSyncDate,
}) => {
  const { loggedUser } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage(); // useMessage hook

  const [syncLoading, setSyncLoading] = useState(false);
  const [syncLogs, setSyncLogs] = useState();

  useEffect(() => {
    if (integrationData.fechaDesde && integrationData.fechaHasta) {
      form.setFieldsValue({
        dates: [
          dayjs(integrationData.fechaDesde),
          dayjs(integrationData.fechaHasta),
        ],
        URL: integrationData.url,
        ReturnURL: integrationData.returnUrl,
        WebhookURL: integrationData.webhookUrl,
        UsaCoeficiente: integrationData.usaCoeficiente,
      });
    }
  }, [integrationData, form]);

  const handleCopyToClipboard = (value, fieldName) => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          messageApi.success(`Copiado ${fieldName} al portapapeles`);
        })
        .catch((err) => {
          console.error("Failed to copy value: ", err);
          messageApi.error("Failed to copy value");
        });
    }
  };

  const renderConfigType = () => {
    if (integrationData.tipoConfiguracion === "I") {
      return "Indirecto";
    } else {
      return "Directo";
    }
  };

  const isValidDate = (date) => {
    return (
      dayjs(date).isValid() &&
      date !== "" &&
      date !== null &&
      date !== undefined
    );
  };

  const renderMobbexSync = () => {
    if (drawerIntegration?.integration?.codigo === "MB") {
      return (
        <Form.Item
          name={"UltimaSinc"}
          label={<span className="form-label">Última sincronización</span>}
        >
          <div className="sync-wrapper">
            <Typography.Text>
              {isValidDate(lastSyncDate)
                ? dayjs(lastSyncDate).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Typography.Text>
            <Tooltip title={"Sincronizar ahora"}>
              <SyncOutlined
                spin={syncLoading}
                style={{ cursor: "pointer", padding: "0 12px 0 12px" }}
                onClick={() => onClickSync()}
              />
            </Tooltip>
          </div>
        </Form.Item>
      );
    } else {
      return null;
    }
  };

  const onClickSync = async () => {
    setSyncLoading(true); // Inicia el estado de carga
    const result = await GETSincronizacion(
      loggedUser,
      drawerIntegration.integration
    );

    setTimeout(() => {
      setSyncLoading(false); // Finaliza el estado de carga después de 1500 ms
      if (result) {
        setSyncLogs(result);
        setLastSyncDate(dayjs(result.fecha).subtract(3, "hour"));
      }
    }, 1500);
  };

  const formatDate = (date) => {
    if (date === "0001-01-01T00:00:00") {
      return "-";
    }

    return dayjs(date)
      .tz("America/Argentina/Buenos_Aires")
      .format("DD/MM/YYYY");
  };

  const syncLogDisplay = () =>
    syncLogs && (
      <div style={containerStyle}>
        <div style={headerStyle}>
          <span>Resultados</span>
        </div>
        <div style={logContainerStyle}>
          {syncLogs.mensaje && <div style={logStyle}>{syncLogs.mensaje}</div>}
          {syncLogs.mediosPago && (
            <div style={logStyle}>
              <strong>Medios de Pago:</strong> {syncLogs.mediosPago}
            </div>
          )}
          {syncLogs.planesPago && (
            <div style={logStyle}>
              <strong>Planes de Pago:</strong> {syncLogs.planesPago}
            </div>
          )}
        </div>
      </div>
    );
  return (
    <>
      {contextHolder}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name={"dates"}
          label={<span className="form-label">Fecha desde - hasta</span>}
        >
          <Space.Compact style={{ width: "100%" }}>
            <Input
              readOnly
              style={{
                width: "50%",
              }}
              value={formatDate(integrationData.fechaDesde)}
            />
            <Input
              readOnly
              style={{
                width: "50%",
              }}
              value={formatDate(integrationData.fechaHasta)}
            />
          </Space.Compact>
        </Form.Item>

        <Form.Item name={"URL"} label={<span className="form-label">URL</span>}>
          <Input
            readOnly
            addonAfter={
              <CopyOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyToClipboard(form.getFieldValue("URL"), "URL")
                }
              />
            }
          />
        </Form.Item>
        <Form.Item
          name={"ReturnURL"}
          label={<span className="form-label">Return URL</span>}
        >
          <Input
            readOnly
            addonAfter={
              <CopyOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyToClipboard(
                    form.getFieldValue("ReturnURL"),
                    "Return URL"
                  )
                }
              />
            }
          />
        </Form.Item>
        <Form.Item
          name={"WebhookURL"}
          label={<span className="form-label">Webhook URL</span>}
        >
          <Input
            readOnly
            addonAfter={
              <CopyOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyToClipboard(
                    form.getFieldValue("WebhookURL"),
                    "Webhook URL"
                  )
                }
              />
            }
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={<span className="form-label">Tipo de configuración</span>}
            >
              <Typography.Text>{renderConfigType()}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
            <Form.Item
              label={<span className="form-label">Tipo de integración</span>}
            >
              <Typography.Text>
                {integrationData?.tipoIntegracion}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={"UsaCoeficiente"}
          label={<span className="form-label">Coeficiente</span>}
        >
          <Switch
            checkedChildren={<span>Si</span>}
            unCheckedChildren={<span>No</span>}
            defaultChecked
            disabled
          />
        </Form.Item>
        {renderMobbexSync()}
      </Form>

      {syncLogDisplay()}
    </>
  );
};

export default DataForm;

const containerStyle = {
  border: "1px solid #d9d9d9",
  borderRadius: "8px",
  backgroundColor: "#f7f7f7",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  overflow: "hidden",
  fontFamily: "Arial, sans-serif",
};

const headerStyle = {
  backgroundColor: "#2c3e50",
  color: "#fff",
  padding: "12px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "13px",
  fontWeight: "bold",
};

const logContainerStyle = {
  padding: "12px",
  maxHeight: "300px",
  overflowY: "auto",
  backgroundColor: "#fff",
  fontSize: "12px",
  lineHeight: "1.5",
};

const logStyle = {
  backgroundColor: "#f0f0f0",
  marginBottom: "10px",
  padding: "10px",
  borderRadius: "4px",
  borderLeft: "4px solid #004080",
  wordWrap: "break-word",
};
