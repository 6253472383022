import { Button, Typography } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import React from "react";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="app-container">
      <div className="main-content">
        <div className="not-found-wrapper">
          <Typography.Text strong className="main-text">
            404
          </Typography.Text>
          <div className="bottom-wrapper">
            <Typography.Title level={5}>
              La página solicitada no existe.
            </Typography.Title>
            <Button type="primary" onClick={() => navigate("/")}>
              Volver
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
