import axios from "axios";

/**
 * Genera un token para MPQR.
 * @param {string} codEmpresa - Código de la empresa.
 * @param {string} codIntegracion - Código de la integración.
 * @param {string} token - Token de autorización Bearer.
 * @returns {Promise<object>} - La respuesta del servidor.
 */
const GETAutorizacionMP = async (loggedUser, codIntegracion) => {
  const { tokenDatos, codEmpresa } = loggedUser;
  const url = `https://oneposapiqa.innercor.com.ar/api/Integraciones/GenerarToken?codEmpresa=${codEmpresa}&codIntegracion=${codIntegracion}`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${tokenDatos.accessToken}`,
    },
  };

  try {
    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Error en la solicitud de token");
    }
  } catch (error) {
    console.error("Error al generar el token:", error);
    return {
      status: error.response ? error.response.status : 500,
      message: error.message,
    };
  }
};

export default GETAutorizacionMP;
