import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import GETSucursalesPorEmpresa from "../../../../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import GETDispositivosPorSucursal from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/GETDispositivosPorSucursal";
import GETDispositivosDisponibles from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/GETDispositivosDisponibles";
import {
  Spin,
  Row,
  Col,
  Typography,
  Switch,
  Empty,
  Input,
  Space,
  Tooltip,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import PATCHCambiarModoOperacion from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/PATCHCambiarModoOperacion";
import { useNotification } from "../../../../notification/OpenNotification";
import GETSicronizarDispositivos from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/GETSincronizarDispositivos";

const DispositivosForm = ({ integrationData }) => {
  const openNotification = useNotification();
  const { loggedUser } = useContext(GlobalContext);
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncLoading, setSyncLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const sucursalesData = await GETSucursalesPorEmpresa(
          loggedUser,
          loggedUser?.codEmpresa
        );

        const sucursalesConCajasYDispositivos = await Promise.all(
          sucursalesData.map(async (sucursal) => {
            const dispositivosPorCaja = await Promise.all(
              sucursal.cajas.map(async (caja) => {
                const dispositivos = await GETDispositivosPorSucursal(
                  loggedUser,
                  integrationData?.codigo,
                  loggedUser?.codEmpresa,
                  sucursal.codigo
                );

                const dispositivosDisponibles =
                  await GETDispositivosDisponibles(
                    loggedUser,
                    integrationData?.codigo,
                    loggedUser?.codEmpresa,
                    sucursal.codigo,
                    caja.codigoERP || caja.codCajaInternoMP
                  );

                const dispositivosFiltrados = dispositivos
                  .map((dispositivo) => {
                    const disponibles = dispositivosDisponibles.filter(
                      (disp) =>
                        disp.codDispositivo === dispositivo.codDispositivo
                    );

                    return {
                      ...dispositivo,
                      disponibles: disponibles.length > 0,
                    };
                  })
                  .filter(
                    (dispositivo) => dispositivo.codCaja === caja.codigoERP
                  );

                return {
                  ...caja,
                  dispositivos: dispositivosFiltrados,
                };
              })
            );

            return {
              ...sucursal,
              cajas: dispositivosPorCaja,
            };
          })
        );

        setSucursales(sucursalesConCajasYDispositivos);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loggedUser, integrationData]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
        }}
      >
        <Spin size="default" />
      </div>
    );
  }

  // const onClickSync = async () => {
  //   try {
  //     setSyncLoading(true);
  //     const { data, status } = await GETSicronizarDispositivos(
  //       loggedUser,
  //       integrationData?.codigo,
  //       loggedUser?.codEmpresa
  //     );

  //     if (!Array.isArray(data) || data.length === 0) {
  //       console.error("El response no es un array o está vacío");
  //       return;
  //     }

  //     const hardcode = {
  //       codIntegracion: "MPP",
  //       codIntegracionPos: "PAX_A910__SMARTPOS1495271016",
  //       modoOperacion: "PDV",
  //       codigoERP: "00020001",
  //       codSucursal: "SUC002",
  //       nombreSucursal: "Sucursal 2",
  //       codCaja: "00020001",
  //       nombreCaja: "Caja 1",
  //     };

  //     const updatedSucursales = sucursales.map((sucursal) => {
  //       const updatedCajas = sucursal.cajas.map((caja) => ({
  //         ...caja,
  //         dispositivos: [],
  //       }));

  //       return {
  //         ...sucursal,
  //         cajas: updatedCajas,
  //       };
  //     });

  //     data.forEach((dispositivo) => {
  //       updatedSucursales.forEach((sucursal) => {
  //         sucursal.cajas.forEach((caja) => {
  //           if (dispositivo.codigoERP === caja.codigoERP) {
  //             caja.dispositivos.push(dispositivo);
  //           }
  //         });
  //       });
  //     });

  //     setTimeout(() => {
  //       setSyncLoading(false);
  //       if (status === 200) {
  //         openNotification({
  //           type: "success",
  //           message: "Sincronización exitosa.",
  //           duration: 1.5,
  //         });
  //       }
  //       setSucursales(updatedSucursales);
  //     }, 1500);
  //   } catch (error) {
  //     console.error("Error al sincronizar dispositivos:", error);
  //   }
  // };

  const onClickSync = async () => {
    try {
      setSyncLoading(true);
      const { data, status } = await GETSicronizarDispositivos(
        loggedUser,
        integrationData?.codigo,
        loggedUser?.codEmpresa
      );

      if (!Array.isArray(data) || data.length === 0) {
        console.error("El response no es un array o está vacío");
        setSyncLoading(false);
        return;
      }

      // // Hardcodear dispositivos antes de procesar la data
      // const hardcode = {
      //   codIntegracion: "MPP",
      //   codIntegracionPos: "PAX_A910__SMARTPOS999999",
      //   modoOperacion: "STANDALONE",
      //   codigoERP: "00010002",
      //   codSucursal: "SUC001",
      //   nombreSucursal: "Sucursal 2",
      //   codCaja: "00010002",
      //   nombreCaja: "Caja 1",
      // };

      // const hardcode2 = {
      //   codIntegracion: "MPP",
      //   codIntegracionPos: "PAX_A910__SMARTPOS33333",
      //   modoOperacion: "PDV",
      //   codigoERP: "00030001",
      //   codSucursal: "SUC003",
      //   nombreSucursal: "Sucursal 3",
      //   codCaja: "00030001",
      //   nombreCaja: "Caja 1",
      // };

      // const hardcode3 = {
      //   codIntegracion: "MPP",
      //   codIntegracionPos: "PAX_A910__SMARTPOS1495271016",
      //   modoOperacion: "STANDALONE",
      //   codigoERP: "00010001",
      //   codSucursal: "SUC002",
      //   nombreSucursal: "Sucursal 2",
      //   codCaja: "00010001",
      //   nombreCaja: "Caja 1",
      // };

      // Inyectar los dispositivos hardcodeados en la data

      // Limpiar todos los dispositivos de todas las cajas
      const clearedSucursales = sucursales.map((sucursal) => ({
        ...sucursal,
        cajas: sucursal.cajas.map((caja) => ({
          ...caja,
          dispositivos: [],
        })),
      }));

      // Asignar los dispositivos a sus respectivas cajas
      data.forEach((dispositivo) => {
        clearedSucursales.forEach((sucursal) => {
          sucursal.cajas.forEach((caja) => {
            if (dispositivo.codigoERP === caja.codigoERP) {
              caja.dispositivos.push(dispositivo);
            }
          });
        });
      });

      // Actualizar el estado de las sucursales con la nueva asignación
      setTimeout(() => {
        setSyncLoading(false);
        if (status === 200) {
          openNotification({
            type: "success",
            message: "Sincronización exitosa.",
            duration: 1.5,
          });
        }
        setSucursales(clearedSucursales);
      }, 1500);
    } catch (error) {
      console.error("Error al sincronizar dispositivos:", error);
      setSyncLoading(false);
    }
  };

  const handleChangeMode = async (dispositivo) => {
    if (dispositivo && dispositivo.modoOperacion === "STANDALONE") {
      const { codIntegracion, codigoERP } = dispositivo;
      const response = await PATCHCambiarModoOperacion(
        loggedUser,
        codIntegracion,
        codigoERP
      );
      if (response.status === 200) {
        const updatedSucursales = sucursales.map((sucursal) => {
          if (sucursal.codigo === dispositivo.codSucursal) {
            return {
              ...sucursal,
              cajas: sucursal.cajas.map((caja) => {
                if (caja.codigoERP === dispositivo.codigoERP) {
                  const dispositivosActualizados = caja.dispositivos.map(
                    (disp) => {
                      if (
                        disp.codIntegracionPos === dispositivo.codIntegracionPos
                      ) {
                        return {
                          ...disp,
                          modoOperacion: "PDV",
                        };
                      }
                      return disp;
                    }
                  );

                  return {
                    ...caja,
                    dispositivos: dispositivosActualizados,
                  };
                }
                return caja;
              }),
            };
          }
          return sucursal;
        });

        setSucursales(updatedSucursales);
        openNotification({
          type: "success",
          message: response?.data?.mensaje,
          duration: 1.5,
        });
      }
    }
  };

  const controlModo = (modo) => {
    if (modo === "STANDALONE") {
      return false;
    }
    return true;
  };

  const renderTooltipModo = (modo) => {
    if (modo === null || modo === "PDV") {
      return "Modo PDV";
    }
    return "Modo STANDALONE";
  };

  return (
    <>
      <Spin spinning={syncLoading}>
        {sucursales && sucursales.length > 0 ? (
          sucursales.map((sucursal) => (
            <React.Fragment key={sucursal.codigo}>
              <Typography.Text strong>• {sucursal.nombre}</Typography.Text>
              {sucursal?.cajas?.some(
                (caja) => caja?.dispositivos?.length > 0
              ) ? (
                sucursal.cajas.map((caja) => {
                  if (caja?.dispositivos?.length > 0) {
                    const cajaId = caja.codigoERP || caja.codCajaInternoMP;

                    return (
                      <div key={cajaId}>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Space.Compact
                              style={{
                                width: "100%",
                                margin: "10px 0",
                                paddingLeft: "15px",
                              }}
                            >
                              <Typography.Text>{caja.nombre}</Typography.Text>
                            </Space.Compact>
                          </Col>
                        </Row>

                        <Row
                          gutter={16}
                          style={{ marginBottom: 16, paddingLeft: "20px" }}
                        >
                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                              alignItems: "baseline",
                            }}
                          >
                            <Space.Compact>
                              <Input
                                value={caja?.dispositivos?.[0]?.codigoERP}
                                style={{
                                  width: "40%",
                                }}
                              />
                              <Input
                                value={
                                  caja?.dispositivos?.[0]?.codIntegracionPos
                                }
                              />
                            </Space.Compact>
                            <Tooltip
                              title={renderTooltipModo(
                                caja?.dispositivos?.[0].modoOperacion
                              )}
                            >
                              <Switch
                                onChange={() =>
                                  handleChangeMode(caja?.dispositivos?.[0])
                                }
                                checked={controlModo(
                                  caja?.dispositivos?.[0].modoOperacion
                                )}
                                checkedChildren={<span>I</span>}
                                unCheckedChildren={<span>0</span>}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 20,
                  }}
                >
                  <Typography.Text style={{ color: "grey" }}>
                    No hay cajas con dispositivos disponibles.
                  </Typography.Text>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text style={{ color: "grey" }}>
                No hay sucursales disponibles.
              </Typography.Text>
            }
          />
        )}
        <div className="sync-disp-wrapper">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="form-label">Sincronización de dispositivos</span>
            <Tooltip title={"Sincronizar ahora"}>
              <SyncOutlined
                className="edit-icon"
                spin={syncLoading}
                style={{ cursor: "pointer", padding: "0 12px 0 12px" }}
                onClick={() => onClickSync()}
              />
            </Tooltip>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DispositivosForm;
