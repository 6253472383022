export const importAssets = (context) => {
  let images = {};
  context.keys().forEach((item) => {
    const key = item
      .replace("./", "")
      .replace(/\.(png|svg|jpg|jpeg|gif|webp)$/, "");
    images[key] = context(item);
  });
  return images;
};
