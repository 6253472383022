// src/components/Login.js
import React, { useContext, useState } from "react";
import { Button, Card, Form, Image, Input, Typography } from "antd";
import "./index.css";
import { POSTLogin } from "../../helpers/login/POSTlogin";
import { GlobalContext } from "../context/GlobalContext";
import { saveDataInStorage } from "../../utils/manageLocalStorage";
import GETListarIntegraciones from "../../helpers/integraciones/GETListarIntegraciones";
import innercorlogo from "../../assets/AAA1AA.png";
import { getDeployEnvironment } from "../../utils/getDeployEnvironment";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

const Login = () => {
  const currentVersion = process.env.REACT_APP_VERSION;
  const [loadings, setLoadings] = useState([]);
  const [loginError, setLoginError] = useState();
  const [form] = Form.useForm();
  const { setLoggedUser, setAllIntegrations } = useContext(GlobalContext);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2500);
  };

  const onFinish = async (values) => {
    const result = await POSTLogin(values);

    if (result.status === 200) {
      setTimeout(() => {
        const fetchData = async () => {
          // Sale a buscar las integraciones en el caso de tener iniciar
          const { codEmpresa } = result.data;
          const integrationsData = await GETListarIntegraciones(
            result.data,
            codEmpresa
          );
          setAllIntegrations(integrationsData);
        };
        fetchData();
        setLoggedUser(result.data);
        saveDataInStorage("userInfo", result.data);
      }, 2000);
    } else {
      try {
        setTimeout(() => {
          if (result && result.data) {
            const backendErrorMessage = result.data.mensaje;

            const splited = backendErrorMessage.split("  ");

            if (backendErrorMessage) {
              setLoginError({ message: splited[1] });
            }
          } else {
            setLoginError({ message: "Error interno" });
          }
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    // escucha de cambios en input para eliminar mensaje de error al loguear
    if (e) {
      setLoginError();
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-content">
        <Card className="card-wrapper">
          <div className="header-login-wrapper">
            <Image width={90} src={innercorlogo} preview={false} />
            <Typography.Title level={5}>OnePOS</Typography.Title>
          </div>
          <Form
            requiredMark={false}
            style={{ width: "320px" }}
            form={form}
            layout="vertical"
            name="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            onValuesChange={(e) => handleInputChange(e)} // Manejador para cambios en valores del formulario
          >
            <Form.Item
              // label={<span className="form-label">Usuario</span>}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su email",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Ingrese su email" />
            </Form.Item>

            <Form.Item
              // label={<span className="form-label">Contraseña</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su contraseña",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                width={"360px"}
                placeholder="Ingrese su contraseña"
              />
            </Form.Item>

            {loginError ? (
              <div className="error-wrapper">{loginError.message}</div>
            ) : (
              <div className="error-wrapper"></div>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadings[0]}
                block
                onClick={() => {
                  const { email, password } = form.getFieldsValue();
                  if (email && password) {
                    enterLoading(0);
                  }
                }}
              >
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="login-footer">
        <span
          className="about-innercor"
          onClick={() => {
            window.open("https://innercor.com.ar/#!/-innercor/", "_blank");
          }}
        >
          © {new Date().getFullYear()} Innercor Soluciones ERP
        </span>

        <div className="legal-version-wrapper">
          <span className="about-innercor">Legales Privacidad</span>
          <span>
            v {currentVersion} {getDeployEnvironment()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
