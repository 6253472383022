/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Divider, Image, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import DrawerIntegracion from "./drawerIntegracion/DrawerIntegracion";
import { GlobalContext } from "../context/GlobalContext";
import MainLayout from "../mainLayout/MainLayout";
import { importAssets } from "../../utils/importAssets";

// Recibe como props integraciones al igual que MediosPago
const Integraciones = ({ integrations }) => {
  const images = importAssets(
    require.context("../../assets/integraciones", false, /\.(png|svg)$/)
  );

  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [inactiveIntegrations, setInactiveIntegrations] = useState([]);
  const [drawerIntegration, setDrawerIntegration] = useState(null); // Envia como props el status del drawer y la integracion elegida para configurar

  const { selectedIntegration, setSelectedIntegration } =
    useContext(GlobalContext);

  const toggleSelection = (integration) => {
    setSelectedIntegration(integration); // Selecciona globalmente la integración
  };

  const handleTitleClick = (e, integration) => {
    // Detener la propagación del evento para evitar que el click en el título active el click en el Card
    setDrawerIntegration({ visible: true, integration });
    e.stopPropagation();
  };

  useEffect(() => {
    if (integrations && integrations.length > 0) {
      const active = integrations
        .filter((item) => item.activo)
        .sort((a, b) =>
          a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
        );

      const inactive = integrations
        .filter((item) => !item.activo)
        .sort((a, b) =>
          a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
        );

      if (!selectedIntegration) {
        setSelectedIntegration(active[0]); // Set primer integración como default, sino hay otra
      }
      setActiveIntegrations(active);
      setInactiveIntegrations(inactive);
    } else {
      setActiveIntegrations([]);
      setInactiveIntegrations([]);
    }
  }, [integrations]); // eslint-disable-next-line

  const renderIntegrations = (integrations, isActive) => {
    if (!integrations || integrations.length === 0) {
      return (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Typography.Text type="secondary">
            No hay integraciones disponibles.
          </Typography.Text>
        </div>
      );
    }

    return integrations.map((item, index) => {
      const imageSrc = images[item.codigo];
      const isSelected =
        item.idIntegracion === selectedIntegration?.idIntegracion;

      return (
        <Card
          style={{
            width: "135px",
            height: "135px",
            textAlign: "center",
            display: "flex",
            borderColor: isActive && isSelected ? "#808080a1" : "transparent",
          }}
          bordered={isActive && isSelected}
          key={item.idIntegracion || index}
          className={`card-image ${!isActive ? "disabled-card" : ""}`}
          hoverable={isActive}
          onClick={isActive ? () => toggleSelection(item) : undefined}
        >
          <Image src={imageSrc} preview={false} width={50} height={50} />
          <p>{item.name}</p>
          <Card.Meta
            title={
              <div
                style={{
                  height: "45px",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <Typography.Text
                  className={isActive ? "integration-name" : ""}
                  strong={false}
                  onClick={(e) => isActive && handleTitleClick(e, item)}
                >
                  {item.nombre}
                </Typography.Text>
              </div>
            }
          />
        </Card>
      );
    });
  };

  return (
    <MainLayout>
      <div className="integration-wrapper">
        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Activas
        </Typography.Title>
        <div className="cards-integration">
          {renderIntegrations(activeIntegrations, true)}
        </div>
        <Divider />

        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Inactivas
        </Typography.Title>
        <div className="cards-integration">
          {renderIntegrations(inactiveIntegrations, false)}
        </div>
      </div>
      <DrawerIntegracion
        drawerIntegration={drawerIntegration}
        setDrawerIntegration={setDrawerIntegration}
      />
    </MainLayout>
  );
};

export default Integraciones;
