import axios from "axios";

const PATCHCambiarModoOperacion = async (
  loggedUser,
  codIntegracion,
  codDispositivoERP
) => {
  try {
    const { codEmpresa, tokenDatos } = loggedUser;

    let config = {
      method: "PATCH",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/Dispositivos/CambiarModoOperacion?codIntegracion=${codIntegracion}&codEmpresa=${codEmpresa}&CodDispositivoERP=${codDispositivoERP}`,
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${tokenDatos.accessToken}`,
      },
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    } else {
      return {
        status: response.status,
        message: "Error al actualizar el modo de operación",
      };
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};

export default PATCHCambiarModoOperacion;
