import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Tooltip, message } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
  LockOutlined,
  CopyOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import GETAutorizacionMP from "../../../../helpers/integraciones/GETAutorizacionMP";
import { GlobalContext } from "../../../context/GlobalContext";

const CredentialsForm = ({ integrationData }) => {
  const { loggedUser } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [showAPISecret, setShowAPISecret] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiUrl, setApiUrl] = useState(null); // Estado para almacenar la apiUrl
  useEffect(() => {
    form.setFieldsValue({
      APIKey: integrationData?.apiKey || "",
      APISecret: integrationData?.apiSecret || "",
    });
  }, [form, integrationData]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("URL copiada al portapapeles");
      })
      .catch((err) => {
        message.error("Error al copiar al portapapeles");
        console.error(err);
      });
  };

  // Función para redirigir al usuario a la URL
  const handleRedirect = (url) => {
    window.open(url, "_blank"); // Redirige en una nueva pestaña
  };

  const toggleShowAPIKey = () => {
    setShowAPIKey((prev) => !prev);
  };

  const toggleShowAPISecret = () => {
    setShowAPISecret((prev) => !prev);
  };

  const renderMPAuthRequest = () => {
    const { codigo, expireToken } = integrationData;

    if (
      (codigo === "MPP" || codigo === "MPQR" || codigo === "MP") &&
      expireToken === "0001-01-01T00:00:00"
    ) {
      return (
        <>
          <Form.Item label={""}>
            <Button
              style={{
                backgroundColor: "#009ee3",
                borderColor: "#009ff1",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleAsyncOperation}
              disabled={loading}
            >
              {loading ? (
                <LoadingOutlined style={{ color: "white" }} />
              ) : (
                <LockOutlined />
              )}
              {loading
                ? "Solicitando autorización..."
                : "Solicitar autorización"}
            </Button>
          </Form.Item>

          {!apiUrl && (
            <Form.Item>
              <Typography.Text
                style={{
                  color: "gray",
                  fontStyle: "italic",
                  fontSize: "12px",
                  display: "block",
                  marginTop: "-10px",
                }}
              >
                Al solicitar la autorización de Mercado Pago, se le brindarán
                dos opciones: copiar la URL para completarla manualmente o ser
                redirigido automáticamente haciendo clic en el botón.
              </Typography.Text>
            </Form.Item>
          )}
        </>
      );
    }
    return null;
  };

  const handleAsyncOperation = async () => {
    setLoading(true);
    try {
      const response = await GETAutorizacionMP(
        loggedUser,
        integrationData.codigo
      );

      if (response.status === 200 && response.data) {
        setApiUrl(response.data.apiUrl);
      }
    } catch (error) {
      console.error("Error al solicitar autorización:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderApiUrl = () => {
    if (apiUrl) {
      return (
        <Form.Item name="authMP" label="" initialValue={apiUrl}>
          <Input
            readOnly
            addonAfter={
              <>
                <Tooltip title="Copiar al portapapeles">
                  <CopyOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopyToClipboard(apiUrl)}
                  />
                </Tooltip>
                <Tooltip title="Redirigir">
                  <ExportOutlined
                    style={{ cursor: "pointer", marginLeft: 10 }}
                    onClick={() => handleRedirect(apiUrl)}
                  />
                </Tooltip>
              </>
            }
          />
        </Form.Item>
      );
    }
    return null;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      name="credentialsForm"
    >
      <Form.Item
        label={<span className="form-label">API Key</span>}
        name="APIKey"
      >
        <Input
          type={showAPIKey ? "text" : "password"}
          suffix={
            <Button
              type="text"
              icon={showAPIKey ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={toggleShowAPIKey}
            />
          }
          readOnly
        />
      </Form.Item>

      <Form.Item
        label={<span className="form-label">API Secret</span>}
        name="APISecret"
      >
        <Input
          type={showAPISecret ? "text" : "password"}
          suffix={
            <Button
              type="text"
              icon={showAPISecret ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={toggleShowAPISecret}
            />
          }
          readOnly
        />
      </Form.Item>
      {renderMPAuthRequest()}
      {renderApiUrl()}
    </Form>
  );
};

export default CredentialsForm;
